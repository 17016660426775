<app-page-title icon="fa-rocket" [title]="integration?.name">
  <button class="btn btn-primary btn-icon-right" routerLink="/integrations">
    Voltar
    <i class="fa fa-arrow-left"></i>
  </button>
</app-page-title>

<h2>Últimos {{ postbacksCount > 0 ? postbacksCount : '' }} postbacks recebidos</h2>

<div *ngIf="integration?.postbacks.length > 0; else notFound">
  <app-accordion-panel *ngFor="let postback of integration.postbacks; let i = index"
    [title]="'Data/hora: ' + (postback.created_at | date:'dd/MM/yyyy HH:mm:ss')" data-testid="postback-item">
    <div class="copy">
      <i class="fa fa-file" (click)="copy(i)" ngbTooltip="Copiar postback"></i>
    </div>
    <pre data-testid="postback-content">{{ postback.data | json }}</pre>
    <app-hidden-field [name]="'postback-' + i" value="{{ postback.data | prettyJson }}"></app-hidden-field>
  </app-accordion-panel>
</div>

<ng-template #notFound>
  <div class="card" *ngIf="!loading">
    <div class="col" data-testid="no-postback">
      Nenhum postback recebido!
    </div>
  </div>
</ng-template>
