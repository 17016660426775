<ng-template #checkoutCrossTooltip>
  <div class="d-flex flex-column" style="width: 30vw !important; min-width: 30vw;">
    <b>
      Utilize o Cross Checkout para impulsionar suas vendas!
    </b>
    <span>
      Aproveite essa opção para direcionar seus clientes a uma página de checkout personalizada, onde os dados do cliente serão automaticamente preenchidos, tornando o processo de compra mais fluido e aumentando as chances de conversão.
    </span>
  </div>
</ng-template>

<div class="sequences">
  <h3 class="mb-2" *ngIf="sequences">
    <p>Sequência</p>
    <button
      type="button"
      class="btn btn-primary btn-icon-right"
      (click)="new(modalSequence)"
      data-testid="btn-new-sequence"
    >
      Adicionar Ação
      <i class="fa fa-plus-circle"></i>
    </button>
  </h3>

  <section
    class="card mt-1"
    *ngFor="let sequence of sequences"
    [class.disabled]="!sequence.active"
  >
    <div class="card-body">
      <div class="row d-flex align-items-center">
        <div class="col-md-2">
          <app-switcher
            [color]="!sequence.active"
            [checked]="sequence.active"
            (change)="toggleActive(sequence)"
          >
          </app-switcher>
        </div>

        <div class="col-md-3 d-flex justify-content-center align-items-center flex-column">
          <span class="badge bg-primary me-2">
            <i [class]="getMsgTypeIcon(sequence)" class="fa"></i>
            {{ checkMsgType(sequence, true) }}
          </span>
          Após {{ sequence.interval + " " + sequence.interval_type.name }}
        </div>

        <div class="col-md-4">
          {{ sequence.text || sequence.call4u_campaign_description || sequence.voxuy_campaign_description }}
        </div>

        <div class="col-md-3 buttons">
          <a
            class="btn btn-primary me-1"
            (click)="edit(sequence, modalSequence)"
            data-testid="btn-edit-sequence"
          >
            <i class="fa fa-pencil"></i>
          </a>
          <a class="btn btn-danger" (click)="remove(sequence)" data-testid="btn-removal-sequence">
            <i class="fa fa-trash"></i>
          </a>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #modalSequence let-modal>
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('CLOSE')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="message-options-container">
        <input (click)="activeDropdown()" type="radio" name="msg-type" id="msg-text" value="true" [checked]="[1, 3].includes(msgType)" hidden/>
        <label class="message-option" for="msg-text" ngbTooltip="Clique para ver as opções">
          <h2>Mensagem {{ checkMsgType() == 1 ? 'SMS' : 'WhatsApp' }}
          </h2>
        </label>
        <div class="dropdown-msg-text" [style]="dropDownMessage ? 'display:flex;' : 'display:none;'">
          <button class="btn" (click)="msgType = 1; activeDropdown();">
            <i><img src="/assets/Simbolo_smsfunnel.jpeg" alt="Logo SMS Funnel"></i> SMS
          </button>
          <button class="btn" (click)="msgType = 3; activeDropdown()">
            <i><img src="/assets/Simbolo_Voxuy.png" alt="Logo Voxuy"></i> WhatsApp
          </button>
        </div>
        <input [checked]="msgType == 0" (change)="msgType = 0" type="radio" name="msg-type" id="msg-voice" value="false" hidden/>
        <label class="message-option" for="msg-voice">
          <h2>Mensagem de voz</h2>
          <span *ngIf="!usedCall4U" style="margin: 10px;" class="new">NOVO!</span>
        </label>
        <input [checked]="msgType == 2" (change)="msgType = 2" [disabled]="campaign.ac_infos == null" type="radio" name="msg-type" id="msg-active-campaign" [checked]="(checkMsgType() === 2)" hidden/>
        <label [ngbTooltip]="campaign.ac_infos == null ? 'Ative o Active Campaign antes de utilizar.' : null" class="message-option" for="msg-active-campaign" [style]="!campaign.lead_list ? 'display: none !important' : null" [style]="!campaign.ac_infos ? 'opacity: 50%;cursor:not-allowed;' : ''">
          <h2>Active Campaign</h2>
        </label>
      </div>
      <form *ngIf="msgType == 1" (submit)="save(modal)" [formGroup]="sequenceForm">
        <div class="card gradient-row danger">
          ATENÇÃO: Lembre-se que está enviando SMS e esta tecnologia tem suas
          restrições, portanto, não utilize acento ou emoji nas mensagens!
        </div>
        <div class="row" data-testid="click-form">
          <div class="col-md-7">
            <div class="form-group">
              <label for="text">Mensagem:</label>
              <textarea
                id="text"
                name="text"
                rows="5"
                class="form-control"
                formControlName="text"
                [maxlength]="messageMaxLength"
                (blur)="setPosition($event)"
                (input)="messageContentChanged($event)"
                data-testid="area-message-campaign"
              ></textarea>
              <small
                class="pull-right"
                *ngIf="sequenceForm.get('text').value; else textLengthCounter"
              >
                {{ sequenceForm.get("text").value.length }}/{{
                  messageMaxLength
                }}
              </small>
              <ng-template #textLengthCounter>
                <small class="pull-right">0/{{ messageMaxLength }}</small>
              </ng-template>
              <small
                class="text text-danger"
                *ngIf="sequenceForm.get('text').errors?.maxlength"
              >
                O texto deve ter apenas 150 caracteres
              </small>
            </div>
          </div>
          <div class="col-md-5">
            <b>Variáveis que podem ser utilizadas</b><br />
            <span
              *ngFor="let tag of tags"
              class="badge bg-primary me-2 user-select-none"
              (click)="addTag(tag.tag)"
              [ngbTooltip]="tag.title"
            >
              {{ tag.tag }}
            </span>
          </div>

          <div class="col-md-12 mt-3">
            <div class="form-group">
              <label for="url">Meu Link:</label>
              <input
                type="text"
                name="url"
                id="url"
                class="form-control"
                placeholder="Exemplo: https://exemplo.com"
                formControlName="url"
                data-testid="area-link-campaign"
              />
              <span
                class="text-danger"
                *ngIf="sequenceForm.get('url').errors?.invalidUrl"
                >URL Inválida! Exemplo: <u>https://exemplo.com</u></span
              >
            </div>
          </div>
          <div class="col-md-12 mt-3">
            <div class="form-group">
              <app-switcher id="user_active" label="Enviar número do lead na URL?" display="block" [(checked)]="sendLeadNumber" />
            </div>
          </div>

          <div class="col-md-12 mt-3" *ngIf="have_list_integration">
            <div class="form-group">
              <label for="url" [ngbTooltip]="checkoutCrossTooltip" tooltipClass="cctooltip" style="margin-bottom: 10px">Checkout Cross <span style="margin: 10px; margin-left: 2px;" class="new">NOVO!</span></label>
              <input
                type="text"
                name="cross_checkout_url"
                id="cross_checkout_url"
                class="form-control"
                placeholder="Exemplo: https://exemplo.com"
                formControlName="cross_checkout_url"
                (input)="detectPlatformCrossCheckout()"
              />
              <span
                class="text-danger"
                *ngIf="sequenceForm.get('cross_checkout_url') && sequenceForm.get('cross_checkout_url').getRawValue() && sequenceForm.get('cross_checkout_url').errors?.invalidUrl"
                >URL Inválida! Exemplo: <u>https://exemplo.com</u></span
              >
              <div class="col-12 d-flex justify-content-end align-items-center">
                <span
                  class="text-danger"
                  *ngIf="checkout_cross_platform && checkout_cross_platform !== ''"
                  style="color: green !important; margin-left: 5px; margin-top: 10px;text-align: center;"
                  >
                  Plataforma detectada: {{ checkout_cross_platform }}
                </span>
              </div>
            </div>
          </div>

          <div [class]="have_list_integration ? 'col-md-12 col-sm-12 d-flex flex-row flex-wrap justify-content-between align-items-center mt-2' : 'row flex-wrap'">

            <div class="col-md-5 col-sm-12" *ngIf="have_list_integration">
              <div class="form-group">
                <label for="interval"
                ngbTooltip="Você pode usar este campo para fornecer o cupom de desconto disponível para o cross checkout. Seu cliente não precisará digitar o código, pois nós nos encarregaremos do preenchimento automático."
                style="margin-bottom: 10px;">Cupom <span style="margin: 10px; margin-left: 2px;" class="new">NOVO!</span></label>
                <input
                  type="text"
                  class="form-control"
                  name="coupon"
                  id="coupon"
                  formControlName="coupon"
                  placeholder="Exemplo: DESCONTO100"
                />
              </div>
            </div>

            <div class="col-sm-12" [class]="have_list_integration ? 'col-md-3' : 'col-md-6 mr-2'">
              <div class="form-group">
                <label for="interval" [style]="have_list_integration ? 'margin-bottom: 10px' : ''">Após entrada na lista</label>
                <input
                  type="number"
                  class="form-control"
                  name="interval"
                  id="inteval"
                  formControlName="interval"
                  data-testid="input-enty-list"
                />
              </div>
              <small
                class="text text-danger"
                *ngIf="sequenceForm.get('interval').errors?.required"
                data-testid="alert-interval-required"
              >
                O intervalo é obrigatório
              </small>
            </div>
  
            <div class="col-sm-12" [class]="have_list_integration ? 'col-md-3' : 'col-md-6'" [style]="have_list_integration ? 'margin-top: 10px' : ''">
              <div class="form-group">
                <label for="interval_type_id">&nbsp;</label>
                <select
                  class="form-control"
                  name="interval_type_id"
                  id="interval_type_id"
                  formControlName="interval_type_id"
                  data-testid="select-interval-type"
                >
                  <option
                    *ngFor="let intervalType of intervalTypes"
                    [value]="intervalType.id"
                  >
                    {{ intervalType.name }}
                  </option>
                </select>
              </div>
              <small
                class="text text-danger"
                *ngIf="sequenceForm.get('interval_type_id').errors?.required"
                data-testid="alert-interval-type-required"
              >
                O tipo intervalo é obrigatório
              </small>
            </div>
          </div>
        </div>
      </form>
      <form *ngIf="msgType == 0 && usedCall4U" style="min-height: 30rem;" (submit)="save(modal)" [formGroup]="sequenceForm">
        <div class="form-group link-from-call4u">
          <label style="font-size: 130%;">Link da campanha Call4U</label>
          <input type="text" class="form-control" style="margin-top: 1rem;" placeholder="Digite o link da sua campanha no Call4U" name="call4u_campaign_url" formControlName="call4u_campaign_url"/>
          <label style="font-size: 130%;margin-top: 1rem;">Informe uma descrição breve da sua mensagem</label>
          <input type="text" class="form-control" maxlength="80" style="margin-top: 1rem;" placeholder="Digite uma descrição para identificar esta campanha" name="call4u_campaign_description" formControlName="call4u_campaign_description"/>
          <div class="d-flex justify-content-end align-items-center">
            <h6 style="color: gray; margin-right: .5rem; margin-top: .5rem;float: right;">{{ sequenceForm.get("call4u_campaign_description").value ? sequenceForm.get("call4u_campaign_description").value.length : 0 }}/80</h6>
          </div>

          <div class="timer-container">
            <div class="col-md-6 timer-value">
              <div class="form-group">
                <label for="interval">Após entrada na lista</label>
                <input
                  type="number"
                  class="form-control"
                  name="interval"
                  id="inteval"
                  formControlName="interval"
                />
              </div>
              <small
                class="text text-danger"
                *ngIf="sequenceForm.get('interval').errors?.required"
              >
                O intervalo é obrigatório
              </small>
            </div>

            <div class="col-md-6 timer-type">
              <div class="form-group">
                <label for="interval_type_id">&nbsp;</label>
                <select
                  class="form-control"
                  name="interval_type_id"
                  id="interval_type_id"
                  formControlName="interval_type_id"
                >
                  <option
                    *ngFor="let intervalType of intervalTypes"
                    [value]="intervalType.id"
                  >
                    {{ intervalType.name }}
                  </option>
                </select>
              </div>
              <small
                class="text text-danger"
                *ngIf="sequenceForm.get('interval_type_id').errors?.required"
              >
                O tipo intervalo é obrigatório
              </small>
            </div>
          </div>
        </div>
        <div class="tip-from-call4u">
          <h6 style="font-size: 130%;">Não sabe como fazer? <a [href]="CALL4U_DOCS" target="_blank" style="text-decoration: none;"><b style="color: #00BE9A;">Clique aqui</b></a> e veja o tutorial!</h6>
        </div>
        <small style="margin-left: 25px; user-select: none;">
          Senha padrão para o Call4You: <span (click)="copyPwd()" ngbTooltip="Clique para copiar" class="call4u-pwd" style="cursor: pointer; color: rgb(0, 124, 255);">newCallUser <i style="color: white;" class="fa fa-copy"></i></span>
        </small>
      </form>
      <form *ngIf="msgType == 3" style="min-height: 30rem;" (submit)="save(modal)" [formGroup]="sequenceForm">
        <div class="form-group link-from-voxuy">
          <label style="font-size: 130%;">Link da campanha Voxuy</label>
          <input type="text" class="form-control" style="margin-top: 1rem;" placeholder="Digite o link da sua campanha no Voxuy" name="voxuy_campaign_url" formControlName="voxuy_campaign_url"/>
          <label style="font-size: 130%;margin-top: 1rem;">Informe uma descrição breve da sua mensagem</label>
          <input type="text" class="form-control" maxlength="80" style="margin-top: 1rem;" placeholder="Digite uma descrição para identificar esta campanha" name="voxuy_campaign_description" formControlName="voxuy_campaign_description"/>
          <div class="d-flex justify-content-end align-items-center">
            <h6 style="color: gray; margin-right: .5rem; margin-top: .5rem;float: right;">{{ sequenceForm.get("voxuy_campaign_description").value.length }}/80</h6>
          </div>

          <div class="timer-container">
            <div class="col-md-6 timer-value">
              <div class="form-group">
                <label for="interval">Após entrada na lista</label>
                <input
                  type="number"
                  class="form-control"
                  name="interval"
                  id="inteval"
                  formControlName="interval"
                />
              </div>
              <small
                class="text text-danger"
                *ngIf="sequenceForm.get('interval').errors?.required"
              >
                O intervalo é obrigatório
              </small>
            </div>

            <div class="col-md-6 timer-type">
              <div class="form-group">
                <label for="interval_type_id">&nbsp;</label>
                <select
                  class="form-control"
                  name="interval_type_id"
                  id="interval_type_id"
                  formControlName="interval_type_id"
                >
                  <option
                    *ngFor="let intervalType of intervalTypes"
                    [value]="intervalType.id"
                  >
                    {{ intervalType.name }}
                  </option>
                </select>
              </div>
              <small
                class="text text-danger"
                *ngIf="sequenceForm.get('interval_type_id').errors?.required"
              >
                O tipo intervalo é obrigatório
              </small>
            </div>
          </div>
        </div>
        <div class="tip-from-voxuy">
          <h6 style="font-size: 130%;">Não sabe como fazer? <a href="https://intercom.help/voxuy/pt-BR/" target="_blank" style="text-decoration: none;"><b style="color: #00BE9A;">Clique aqui</b></a> e veja o tutorial!</h6>
        </div>
      </form>
      <form *ngIf="msgType == 2 && campaign.ac_infos !== null" style="min-height: 30rem;" (submit)="save(modal)" [formGroup]="sequenceForm">
        <div class="form-group">
          <div class="col-md-12 d-flex justify-content-center align-items-center mt-4 mb-3">
            <h1 class="mb-0">TAGS</h1>
          </div>
          <div class="row justify-content-between align-items-center">
            <div class="col-md-4 rounded panel">
              <div class="panel-header">
                <h2>DISPONÍVEIS</h2>
                <button
                type="button"
                class="btn btn-secondary"
                (click)="refreshACInfo()"
                [disabled]="refreshAC"
                >
                <i class="fa fa-refresh"></i>
              </button>
                <button
                  type="button"
                  class="btn btn-primary btn-icon-left"
                  (click)="newTag(modalTag)"
                >
                  <i class="fa fa-plus-circle"></i>
                  Nova tag
                </button>
              </div>
              <div class="panel-body">
                  <div class="col-md-12 d-flex flex-row flex-wrap justify-content-start align-items-center">
                    <label *ngFor="let tag of AC_tags">
                      <input type="checkbox" class="badge-select" hidden (click)="preSelectACTagAll(tag)" [checked]="tagIsInPreselected(tag)"/>
                      <span class="a-badge badge bg-primary me-2 d-flex justify-content-center align-items-center" [ngbTooltip]="tag.description" style="min-height: 2.2rem;">
                        {{ tag.tag }}
                        <span (click)="editTag(modalTag, tag)" class="badge-opt" style="margin-left: 1rem;">
                          <i class="fa fa-pencil"></i>
                        </span>
                        <span (click)="deleteTag(tag)" class="badge-opt"><i class="fa fa-trash"></i></span></span>
                  </label>
                </div>
              </div>
              <h6 style="text-align: center; margin-top: .5rem;">{{ AC_preselected_all.length }} Selecionadas</h6>
            </div>
            <div class="divider">
              <button
                type="button"
                class="btn btn-primary mb-4"
                ngbTooltip="Adicionar"
                (click)="toSelect()"
                >
                <i class="fa fa-arrow-right"></i>
              </button>
              <button
                type="button"
                class="btn btn-primary"
                ngbTooltip="Remover"
                (click)="toAll()"
                >
                <i class="fa fa-arrow-left"></i>
              </button>
            </div>
            <div class="col-md-4 rounded panel">
              <div class="panel-header">
                <h2>SELECIONADAS</h2>
              </div>
              <div class="panel-body">
                  <div class="col-md-12 d-flex flex-row flex-wrap justify-content-start align-items-center">
                    <label *ngFor="let tag of AC_selected_tags">
                      <input type="checkbox" class="badge-select" hidden (click)="preSelectACTagSelected(tag)" [checked]="tagIsInPreselected(tag)"/>
                      <span class="a-badge badge bg-primary me-2 d-flex justify-content-center align-items-center" [ngbTooltip]="tag.description" style="min-width: 7rem; max-width: fit-content; min-height: 2.2rem;">
                        {{ tag.tag }}
                      </span>
                  </label>
                </div>
              </div>
              <h6 style="text-align: center; margin-top: .5rem;">{{ AC_preselected_selected.length }} Selecionadas</h6>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-12">
              <div class="form-group">
                <label for="url_active">Meu Link</label>
                <input
                  type="text"
                  class="form-control"
                  name="url"
                  id="url_active"
                  formControlName="url"
                  placeholder="Exemplo: https://exemplo.com"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="interval">Após entrada na lista</label>
                <input
                  type="number"
                  class="form-control"
                  name="interval"
                  id="inteval"
                  formControlName="interval"
                />
              </div>
              <small
                class="text text-danger"
                *ngIf="sequenceForm.get('interval').errors?.required"
              >
                O intervalo é obrigatório
              </small>
            </div>
            <div class="col-md-6 timer-type">
              <div class="form-group">
                <label for="interval_type_id">&nbsp;</label>
                <select
                  class="form-control"
                  name="interval_type_id"
                  id="interval_type_id"
                  formControlName="interval_type_id"
                >
                  <option
                    *ngFor="let intervalType of intervalTypes"
                    [value]="intervalType.id"
                  >
                    {{ intervalType.name }}
                  </option>
                </select>
              </div>
              <small
                class="text text-danger"
                *ngIf="sequenceForm.get('interval_type_id').errors?.required"
              >
                O tipo intervalo é obrigatório
              </small>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <a
      class="btn"
      *ngIf="checkMsgType() == 0"
      ngbTooltip="Abrir Call4U em outra aba"
      href="https://app.call4u.com.br/sign-in"
      target="_blank"
      style="max-height: 36px; background: linear-gradient(45deg, #e9710b, #ff9143);"
      >
      <i class="fa">
        <img src="/assets/call4u.webp" class="call4u-icon"/>
      </i>
      Acessar Call4U
    </a>
      <button
        type="button"
        class="btn btn-primary"
        (click)="save(modal)"
        [disabled]="sequenceForm.invalid"
        data-testid="btn-save-edit-sequence"
      >
        Salvar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #modalTag let-tagModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ tagMode === 1 ? 'Informe o nome da nova tag' : "Edição de tag" }}</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="tagModal.dismiss('CLOSE')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="padding: 3rem;">
    <form (submit)="saveTag(tagModal)" [formGroup]="tagForm">
      <label for="name" class="col-md-12" style="margin-bottom: 1rem;">
        Nome
        <input
          id="name"
          name="name"
          class="form-control"
          formControlName="name"
          placeholder="Digite aqui o nome da sua tag..."
          required
        />
      </label>

      <label for="description" class="col-md-12">
          Descrição
          <input
            id="description"
            name="description"
            class="form-control"
            formControlName="description"
            placeholder="Digite aqui a descrição da sua tag..."
          />
        </label>
    </form>
  </div>

  <div class="modal-footer">
    <button
    type="button"
    class="btn btn-secondary"
    (click)="tagModal.dismiss()"
  >
    Fechar
  </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="saveTag(tagModal)"
      [disabled]="tagForm.invalid"
    >
      Salvar
    </button>
  </div>
</ng-template>


<ac-activate-popup [integrationId]="campaign.lead_list && campaign.lead_list.integration ? campaign.lead_list.integration.id : ''" *ngIf="checkMsgType() == 2 && campaign.ac_infos == null"></ac-activate-popup>
<call4u-activate-popup [onClose]="close" *ngIf="checkMsgType() == 0 && !usedCall4U"></call4u-activate-popup>
