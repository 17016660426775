import { Component } from '@angular/core';
import { FractionationService } from 'src/app/shared/services/fractionations.service';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { Router } from '@angular/router';
import { PaginatedResult } from './../../shared/model/paginated-result';
import { FractionationRoute } from 'src/app/models/Fractionation';
import { User } from './../../models/User';
import { ADMINISTRATOR } from './../../shared/services/user.service';
import { AuthService } from './../../shared/services/auth.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-fractionations',
  templateUrl: './fractionations.component.html',
  styleUrls: ['./fractionations.component.scss'],
})
export class FractionationsComponent {
  pageTitle: string = 'Cadastro e gerenciamento de rotas';

  page = 1;
  perPage = 10;
  result: PaginatedResult<FractionationRoute[]>;
  routes: FractionationRoute[] = [];
  default: boolean = false;
  monitored: boolean = false;
  isModalOpen: boolean = false;
  filterText: string = '';
  allRoutes: FractionationRoute[] = [];
  previousMonitoredRoute = '';
  previousDefaultRoute = '';
  isPreviousMonitoredRouteSeen = false;
  isPreviousDefaultRouteSeen = false;
  activeRoute = false;
  subscriptions: Subscription[] = [];

  user: User;
  private sortRoutes(routes: FractionationRoute[]): void {
    routes.sort((a, b) => {
      if (a.default === b.default) {
        if (a.monitored === b.monitored) {
          return 0;
        }
        return a.monitored ? -1 : 1;
      }
      return a.default ? -1 : 1;
    });
  }

  get administrator() {
    return this.user.profile_id === ADMINISTRATOR;
  }

  constructor(
    private fractionationService: FractionationService,
    private toastService: ToastService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.user = this.authService.getUser();

    if (!this.administrator) {
      this.router.navigate(['/dashboard']);
      return;
    }

    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => {
        console.log(params, 'params');
        if (!params.page) {
          this.pageChanged(1, true);
          return;
        }

        this.page = params.page;
        this.perPage = +params.per_page || this.perPage;
        this.listPaginated();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  pageChanged(page: number, force?: boolean) {
    console.log(page, force);
    const queryParams = { page, per_page: this.perPage };

    this.router.navigate(['/fractionations'], { queryParams });
  }

  listPaginated() {
    this.subscriptions.push(
      this.fractionationService
        .listPaginated(this.page, this.perPage)
        .subscribe((resultData) => {
          this.result = resultData;
          console.log(resultData);
        })
    );
  }

  // You can also add functions to open and close the modal if needed
  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }

  createConfiguration() {
    const routeName = document.getElementById('routeName') as HTMLInputElement;
    const getName = this.result.data.some(
      (route) => route.name === routeName.value.toUpperCase()
    );
    const getDefault = this.result.data.some((route) => route.default === true);
    const getDefaulName = this.result.data.find(
      (route) => route.default === true
    );
    const getMonitored = this.result.data.some(
      (route) => route.monitored === true
    );
    const getMonitoredName = this.result.data.find(
      (route) => route.monitored === true
    );

    if (getDefault && this.default) {
      this.isPreviousDefaultRouteSeen = true;
      document.getElementById('previousDefaulRouteMessage').innerHTML =
        'Já existe uma rota padrão: ' + getDefaulName.name;

      this.toastService.info('Já existe uma rota padrão');

      return;
    }

    if (getMonitored && this.monitored) {
      this.isPreviousMonitoredRouteSeen = true;
      document.getElementById('previousMonitoredRouteMessage').innerHTML =
        'Já existe uma rota monitorada: ' + getMonitoredName.name;

      this.toastService.info('Já existe uma rota monitorada');
      return;
    }

    if (getMonitored && this.monitored) {
      this.toastService.info('Já existe uma rota monitorada');
      return;
    }

    if (getName) {
      this.toastService.info('Já existe uma rota com esse nome');
      return;
    }

    if (this.default || this.monitored) {
      this.activeRoute = true;
    }

    this.fractionationService
      .addRoute({
        name: routeName.value.toUpperCase(),
        monitored: this.monitored,
        default: this.default,
        created_at: new Date(),
        active: this.activeRoute,
        configured: false,
      })
      .subscribe({
        next: (data: FractionationRoute) => {
          this.result.data.push(data);
          this.sortRoutes(this.result.data);
          this.toastService.success('Rota criada com sucesso');
          this.monitored = false;
          this.default = false;
          routeName.value = '';
          this.activeRoute = false;
          document.getElementById('previousMonitoredRouteMessage').innerHTML =
            '';
          document.getElementById('previousDefaulRouteMessage').innerHTML = '';
          this.redirectToEdit(data);

          //aqui irei redirecionar para a nova rota
          this.closeModal();
        },
        error: (error) => {
          this.toastService.error('Erro ao criar a rota');
          console.log(error);
        },
      });
  }

  changeGroupActive(route: FractionationRoute) {
    route.active = !route.active;
    this.fractionationService.changeGroupActive(route).subscribe({
      next: () => {
        //TODO: atualizar a lista de rotas
        this.listPaginated();
      },
      error: (error) => {
        this.toastService.error('Erro ao desativar a rota');
      },
    });
  }
  checkboxDefault() {
    this.default = !this.default;
    console.log(this.default);
    if (this.default) {
      this.monitored = false; // Uncheck the other checkbox if this one is checked
    }
  }

  checkboxMonitored() {
    this.monitored = !this.monitored;
    if (this.monitored) {
      this.default = false; // Uncheck the other checkbox if this one is checked
    }
  }

  redirectToEdit(route: FractionationRoute) {
    console.log(route.id);
    this.router.navigate([`/fractionations/${route.id}`]);
  }

  searchRoutes(routeName: string) {
    this.subscriptions.push(
      this.fractionationService
        .listPaginated(this.page, this.perPage, routeName)
        .subscribe((resultData) => {
          this.result = resultData;
        })
    );
  }
}
