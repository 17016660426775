<section class="card" #collapse="ngbCollapse" [(ngbCollapse)]="hidden">
  <form (submit)="save()" [formGroup]="form">
    <div class="row">
      <div class="form-group col-md-6">
        <label for="name">Nome</label>
        <input
          id="name"
          type="text"
          class="form-control"
          formControlName="name"
          #integration
          data-testid="integration-name"
        />
        <small
          class="text text-danger"
          *ngIf="form.get('name').touched && form.get('name').errors?.required"
        >
          O nome é obrigatório
        </small>
        <small
          class="text text-danger"
          *ngIf="form.get('name').touched && form.get('name').errors?.minlength"
        >
          O nome deve ter ao menos 5 caracteres
        </small>
      </div>

      <div class="form-group col-md-6">
        <label for="platform_id">Plataforma</label>
        <select
          id="platform_id"
          name="platform_id"
          class="form-control"
          formControlName="platform_id"
          data-testid="integration-platform"
        >
          <option *ngFor="let platform of platforms" [value]="platform.id">
            {{ platform.name }}
          </option>
        </select>
        <small
          class="text text-danger"
          *ngIf="
            form.get('platform_id').touched &&
            form.get('platform_id').errors?.required
          "
        >
          Plataforma é obrigatória
        </small>
      </div>

      <div class="form-group col-md-6 mt-3" *ngIf="enableMP && multiPlatform">
        <label for="platform_id">Integração Alternativa <i (click)="openHelpMP()" style="cursor: pointer;" ngbTooltip="Clique para saber mais" class="fa fa-question-circle"></i> </label>
        <select
          id="alternative_integration_id"
          name="alternative_integration_id"
          class="form-control"
          formControlName="alternative_integration_id"
        >
          <option value="">Nenhuma</option>
          <option *ngFor="let integration of filteredIntegrations" [value]="integration.id">
            {{ integration.name }} - {{ integration.platform ? integration.platform.name : 'Plataforma não identificada.' }}
          </option>
        </select>
      </div>

      <div class="col-md-12 row mt-3" *ngIf="enableMP && filteredIntegrations.length > 0">
        <app-switcher
          display="block"
          label="Integração de Múltiplas Plataformas"
          ngbTooltip="Integrar esta plataforma com a lista de outra plataforma"
          id="delete-contacts"
          [(checked)]="multiPlatform"
          (checkedChange)="disableOrEnableMP()"
        ></app-switcher>
      </div>

      <div class="form-group col-12 mt-3" *ngIf="integrationId">
        <label for="url">Url para integração:</label>
        <input
          type="text"
          name="url"
          id="url"
          class="form-control"
          formControlName="url"
          readonly
          (click)="copy()"
        />
      </div>
    </div>
    <div class="text-end mt-3">
      <button class="btn btn-primary me-1" [disabled]="form.invalid" data-testid="btn-save">Salvar</button>
      <span class="btn btn-danger" (click)="collapse.toggle(); toggleHidden.emit(false)">Fechar</span>
    </div>
  </form>
</section>


<ng-template #modalHelpMP let-modalHelpMP>
  <div>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        Ajuda na Integração: Integração Alternativa
      </h5>
    </div>

    <div class="modal-body">
      <h5><b> Como funciona? </b></h5>
      <h6>
        Imagine que você criou uma integração com a plataforma principal sendo a APPMAX e integra como integração alternativa a PAGARME. Portanto, um contato não poderá estar em duas listas ao mesmo tempo entre essas duas plataformas.
        <br><br>
        Resumidamente, você terá o mesmo benefício da lista dinâmica, porém com duas plataformas/integrações!
      </h6>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        (click)="modalHelpMP.dismiss()"
      >
        Entendido
      </button>
    </div>
  </div>
</ng-template>