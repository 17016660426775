<div
  class="switcher {{class}}"
  [style.display]="display"
  >
  <div
    class="switch"
    [class.color]="color"
    [ngbTooltip]="tooltip"
  >
    <input
      type="checkbox"
      [id]="id"
      [name]="name"
      [checked]="checked"
      (click)="toggleChange()"
      #switchCheckbox
      data-testid="switch-checkbox"
    />
    <span class="btnOn" *ngIf="checked">ON</span>
    <span class="btnOff" *ngIf="!checked">OFF</span>
  </div>
  <label *ngIf="label" (click)="switchCheckbox.click()">{{ label }}</label>
</div>
