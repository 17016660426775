<app-page-title icon="fa-users" title="Usuários" data-testid="users-section">
  <a [routerLink]="['/users/new']" class="btn btn-icon-right btn-primary" data-testid="link-to-new-user">
    Novo usuário <i class="fa fa-plus-circle"></i>
  </a>
</app-page-title>

<div class="mb-2">
  <div class="row">
    <div class="col">
      <button class="btn me-2 btn-primary" (click)="filterByStatus()" data-testid="btn-filter-all">
        <i
          class="fa fa-check"
          *ngIf="active === null && promocode === null"
        ></i>
        <span>Todos</span>
      </button>
      <button class="btn me-2 btn-info" (click)="filterByStatus(true)">
        <i class="fa fa-check" *ngIf="active === true"></i>
        <span>Ativos</span>
      </button>
      <button class="btn me-2 btn-secondary" (click)="filterByStatus(false)" data-testid="btn-filter-inactive">
        <i class="fa fa-check" *ngIf="active === false"></i>
        <span>Inativos</span>
      </button>
      <button class="btn me-2 btn-warning" (click)="filterByStatus(null, true)">
        <i class="fa fa-check" *ngIf="promocode"></i>
        <span>Promocode</span>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group mt-2">
        <label>Digite o nome ou email</label>
        <input
          type="text"
          name="filter"
          class="form-control"
          placeholder="Digite o nome ou email"
          [(ngModel)]="filter"
          (keydown)="filterKeyPress($event)"
          data-testid="input-search-user"
        />
      </div>
      <div class="form-group">
        <label>Promocode</label>
        <select [(ngModel)]="promocodeId" class="form-control">
          <option value="" selected>Todos</option>
          <option
            *ngFor="let promotion of promotions"
            [value]="promotion.promocode"
          >
            {{ promotion.promocode }} ({{ promotion.user_credits }})
          </option>
        </select>
      </div>
      <div class="col-xs-12 mt-2 mb-2 d-flex flex-row">
        <div style="width: 90%">
          <label>Ordenar por</label>
          <select
            name="order"
            id="order"
            class="form-control"
            [(ngModel)]="orderBy"
            (change)="orderByChanged()"
          >
            <option value="name">Nome</option>
            <option value="created_at">Data de Cadastro</option>
            <option value="credits">Créditos restantes</option>
          </select>
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-2"
          style="width: 10%"
        >
          <label>Reverso</label>
          <app-switcher
            [checked]="invertedOrder"
            (change)="invertOrder()"
            style="max-height: 30px !important; user-select: none"
          ></app-switcher>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group mb-3">
            <label for="automation_id">Provedor de saída [Automação]</label>
            <select
              name="automation_id"
              id="automation_id"
              class="form-control"
              (change)="filterByAutomation()"
              [(ngModel)]="automationId"
            >
              <option value="">Todas</option>
              <option *ngFor="let provider of providers" [value]="provider.id">
                {{ provider.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col">
          <div class="form-group mb-3">
            <label for="broadcast_id">Provedor de saída [Broadcast]</label>
            <select
              name="broadcast_id"
              id="broadcast_id"
              class="form-control"
              (change)="filterByBroadcast()"
              [(ngModel)]="broadcastId"
            >
              <option value="">Todas</option>
              <option *ngFor="let provider of providers" [value]="provider.id">
                {{ provider.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col">
          <div class="form-group mb-3">
            <label for="shortener_id">Encurtador</label>
            <select
              name="shortener_id"
              id="shortener_id"
              class="form-control"
              (change)="filterByShortener()"
              [(ngModel)]="shortenerId"
            >
              <option value="">Todas</option>
              <option
                *ngFor="let shortener of shorteners"
                [value]="shortener.id"
              >
                {{ shortener.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="mt-2">
        <button class="btn btn-primary" (click)="pageChanged(page)" data-testid="btn-search-user">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
  </div>
  <hr />
</div>

<div *ngIf="result?.data.length > 0; else notFound">
  <app-accordion-card-flat
    [isHidden]="result.data?.length > 1"
    *ngFor="let user of result.data"
    [title]="getTitle(user)"
    [class]="user.active ? 'primary' : 'danger'"
    data-testid="user-item"
  >
    <div class="row">
      <div class="col-md col-sm-12">
        <label>E-mail</label>
        <p>{{ user.email }}</p>
      </div>

      <div class="col-md col-sm-12">
        <label>Data do Cadastro</label>
        <p>{{ user.created_at | date : "dd/MM/yyyy, H:mm" }}</p>
      </div>

      <div class="col-md col-sm-12">
        <label>Telefone</label>
        <p *ngIf="user.phone" class="phone">
          <a
            href="https://wa.me/{{ user.phone }}?text=Olá {{
              user.name
            }}, aqui é da SMSFunnel."
            target="_blank"
            >{{ user.phone }}</a
          >
          <i class="fa fa-whatsapp ms-2"></i>
        </p>
        <p *ngIf="!user.phone">-</p>
      </div>
      <div class="col-md col-sm-12 action mb-2">
        <div class="icon-group">
          <a
            class="btn btn-icon btn-primary me-1"
            [routerLink]="['/users/edit', user.id]"
            ngbTooltip="Editar"
            data-testid="btn-edit-user"
          >
            <i class="fa fa-pencil"></i>
          </a>
          <button
            type="button"
            class="btn btn-icon btn-primary"
            ngbTooltip="Adicionar créditos"
            [routerLink]="['/recharges/add', user.id]"
          >
            <i class="fa fa-plus"></i>
          </button>
          <button
            type="button"
            class="btn btn-icon btn-secondary"
            [ngbTooltip]="user.validated ? 'Remover validação' : 'Validar'"
            (click)="
              user.validated
                ? validate(user, false)
                : validate(user, true)
            "
            *ngIf="activeUser?.id !== user.id"
          >
            <i
              class="fa"
              [ngClass]="{
                'fa-check': !user.validated,
                'fa-times': user.validated
              }"
            ></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngIf="user.reference_user != null" class="col-md col-sm-12">
        <label ngbTooltip="Nome e email do parceiro/agência a qual o usuário é afiliado.">Afiliação</label>
        <p>{{ user.reference_user.name }} <br> {{ user.reference_user.email }}</p>

      </div>
      <div class="col-md col-sm-12">
        <label>SMS Enviados</label>
        <p>{{ user.total_sent }}</p>
      </div>
      <div class="col-md col-sm-12">
        <label>SMS Contratados</label>
        <p>{{ user.total_contracted }}</p>
      </div>
      <div class="col-md col-sm-12">
        <label>SMS Restantes</label>
        <p>{{ user.credits }}</p>
      </div>
      <div class="col-md col-sm-12"></div>
    </div>
    <div class="row">
      <div class="col text-center">
        <button
          class="btn btn-secondary"
          ngbTooltip="Visualizar painel da conta"
          (click)="simulate(user)"
          *ngIf="loggedInUser.id != user.id"
          data-testid="btn-user-viewer"
        >
          <i class="fa fa-user"></i> Visualizar conta do usuário
        </button>
      </div>
    </div>
  </app-accordion-card-flat>
</div>

<ng-template #notFound>
  <b>Nenhuma usuário cadastrado</b>
</ng-template>

<app-pagination
  *ngIf="result"
  [currentPage]="result.current_page"
  [itemsPerPage]="result.per_page"
  [totalItems]="result.total"
  (pageChanged)="pageChanged($event)"
></app-pagination>
