import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './../../shared/services/auth.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatedResult } from './../../shared/model/paginated-result';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { ConfirmationService } from './../../shared/components/confirmation-modal/confirmation.service';
import { CopyService } from './../../shared/services/copy.service';
import { IntegrationsService } from './../../shared/services/integrations.service';
import { ToastService } from './../../shared/components/toast/toast.service';

import { Integration } from './../../models/Integration';
import { Platform } from './../../models/Platform';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit, OnDestroy {

  page = 1;
  perPage = 15;
  active: boolean;

  showUrl: boolean;
  hideAdd = true;

  storeId: string;
  storeToken: string;
  @ViewChild('modalCartpanda') modal: any;

  platforms: Platform[] = [];
  selectedIntegration: Integration;
  result: PaginatedResult<Integration[]>;
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private copyService: CopyService,
    private toastService: ToastService,
    private confirmation: ConfirmationService,
    private integrationsService: IntegrationsService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        this.page = params.page || this.page;
        this.perPage = params.per_page || this.perPage;
        this.active = null;

        if (params.active) {
          this.active = JSON.parse(params.active);
        }

        this.listPaginated();
      }),

      this.authService.userChanged.subscribe(() => this.listPaginated())
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  listPaginated(): void {
    this.subscriptions.push(
      this.integrationsService
        .listPaginated(this.page, this.perPage, this.active)
        .subscribe((result) => {
          this.result = result
        
          this.route.queryParams.subscribe(params => {
            if (params.integration) {
              this.edit(this.findIntegrationById(params.integration));
            };
          })

        })
    );
  }

  add(): void {
    this.selectedIntegration = null;
    if (this.hideAdd) {
      this.toggleAdd();
    }
  }

  toggleAdd(): void {
    this.hideAdd = !this.hideAdd;
  }

  toggleActive(integration: Integration): void {
    integration.active = !integration.active;

    const successMessage = integration.active
      ? 'Integração ativada com sucesso!'
      : 'Integração desativada com sucesso!';

    this.subscriptions.push(
      this.integrationsService.save(integration).subscribe(() => this.toastService.success(successMessage))
    );
  }

  pageChanged(page: any): void {
    let queryParams = {
      page,
      per_age: this.perPage,
      active: this.active
    }

    if (this.active === null) {
      delete queryParams.active;
    }

    this.router.navigate(['/integrations'], { queryParams });
  }

  copy(integration: Integration): void {
    this.copyService.copy(`url-${integration.id}`,
      `URL ${integration.name} da plataforma ${integration.platform.name} copiada com sucesso!`);
  }

  confirmDelete(integration: Integration): void {
    let message = `Deseja realmente excluir a integração ${integration.name}? `;
    message += `<b>ATENÇÃO:</b> Esta ação irá excluir <b>TODAS</b> as listas vinculadas!`;

    this.confirmation
      .show(message, () => this.delete(integration.id));
  }

  edit(integration: Integration): void {
    this.selectedIntegration = integration;
    if (this.hideAdd) {
      this.toggleAdd();
    }
  }

  findIntegrationById(integrationId: string): Integration {
    const r = this.result.data.filter((i) => {
      return i.id === integrationId
    })
    if (r.length === 1) {
      return r[0];
    }
  }

  createLists(integration: Integration): void {
    this.subscriptions.push(
      this.integrationsService.createLeadLists(integration.id).subscribe(() => {
        this.toastService.success('Listas serão criadas em breve!');
      })
    );
  }

  filterByStatus(active: boolean = null) {
    this.active = active;
    this.pageChanged(this.page);
  }

  delete(integrationId: string): void {
    this.subscriptions.push(
      this.integrationsService.delete(integrationId).subscribe(() => {
        this.hideAdd = true;
        this.toastService.success('Integração excluída com sucesso!');
        this.listPaginated();
      })
    );
  }

  saved(ok: boolean): void {
    if (ok) {
      this.listPaginated();
      this.toggleAdd();
    }
  }

  createWebhooks(integration: Integration): void {
    this.modalService.open(this.modal).result.then(res => {
      if (res === 'YES') {
        this.integrationsService.createCartpandaWebhooks(integration.url, this.storeId, this.storeToken)
          .subscribe(
            () => {
              this.toastService.success('Webhooks adicionados com sucesso!')
              this.storeId = null;
              this.storeToken = null;
            },
            (err) => this.toastService.error(err.error.message)
          );
      }
    });
  }
}
