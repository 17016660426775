// src/app/services/routes.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export interface Carrier {
  id?: string;
  title: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

@Injectable({
  providedIn: 'root',
})
export class CarrierService {
  constructor(private http: HttpClient) {}

  listAll(): Observable<Carrier[]> {
    return this.http.get<Carrier[]>(`${environment.apiUrl}/carriers`);
  }
}
