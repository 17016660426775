<app-page-title image="assets/icon-routes.svg" [title]="pageTitle">
</app-page-title>

<div class="header-actions mb-4">
  <div
    class="search"
    style="position: relative; flex-grow: 1; margin-right: 20px"
  >
    <input
      type="text"
      name="filter"
      id="filter"
      class="form-control"
      placeholder="Buscar...."
      style="padding-right: 30px"
      [(ngModel)]="filterText"
      (keyup.enter)="searchRoutes(filterText)"
    />
    <i
      class="fa fa-search"
      style="
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      "
      (click)="searchRoutes(filterText)"
    ></i>
  </div>
  <button (click)="openModal()" class="btn btn-primary btn-icon-right">
    <span style="margin-right: 27px">Adicionar configuração</span>
    <i class="fa fa-plus-circle"></i>
  </button>
</div>

<table class="table table-hover elevated">
  <thead>
    <tr>
      <th class="route-name" style="width: 300px">Nome da Rota</th>
      <th
        class="actions"
        style="text-align: right; width: 170px; padding-right: 205px"
      >
        Ações
      </th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let route of result.data; let last = last"
      [ngClass]="{ 'no-border': last }"
      [style.border-bottom]="last ? 'none' : ''"
    >
      <td class="route-name">{{ route.name }}</td>
      <td class="actions-td" style="text-align: right">
        <ng-container *ngIf="route.default && !route.monitored">
          <span class="route-status"> SAIDA PADRÃO </span>
        </ng-container>
        <ng-container *ngIf="route.monitored && !route.default">
          <span class="route-status"> MONITORADA </span>
        </ng-container>

        <button class="btn btn-left" (click)="redirectToEdit(route)">
          <i
            class="fa fa-pencil"
            aria-hidden="true"
            style="font-size: medium"
          ></i>
          <span style="font-size: medium; margin-left: 5px"> Editar </span>
        </button>

        <ng-container *ngIf="!route.active">
          <!-- Se ambos default e monitored forem falsos, mostre o botão Ativar -->
          <button class="btn btn-right" (click)="changeGroupActive(route)">
            <i
              class="fa fa-plus-circle"
              aria-hidden="true"
              style="font-size: large"
            ></i>
            <span style="font-size: medium; margin-left: 5px"> Ativar </span>
          </button>
        </ng-container>
        <ng-container *ngIf="route.active">
          <!-- Caso contrário, mostre o botão Desativar -->
          <button class="btn" (click)="changeGroupActive(route)">
            <i
              class="fa fa-minus-circle"
              aria-hidden="true"
              style="font-size: large"
            ></i>
            <span style="font-size: medium; margin-left: 5px"> Desativar </span>
          </button>
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>
<app-pagination
  *ngIf="result"
  [currentPage]="result.current_page"
  [itemsPerPage]="result.per_page"
  [totalItems]="result.total"
  (pageChanged)="pageChanged($event)"
></app-pagination>

<div class="modal" [class.show]="isModalOpen" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Nome da configuração</h5>
      </div>
      <div class="modal-body">
        <input
          type="text"
          name="routeName"
          id="routeName"
          class="form-control"
          placeholder="Digite o nome da configuração"
          style="padding-right: 30px"
        />

        <div style="margin-top: 30px">
          <label class="label-checkbox">
            <input
              type="checkbox"
              [checked]="default"
              (change)="checkboxDefault()"
            />
            <span style="margin-left: 8px">Usar como padrão</span>
            <span
              style="margin-left: 70px; color: red"
              id="previousDefaulRouteMessage"
            ></span>
          </label>
        </div>
        <div>
          <label class="label-checkbox">
            <input
              type="checkbox"
              [checked]="monitored"
              (change)="checkboxMonitored()"
            />
            <span style="margin-left: 8px">Saída monitorada</span>
            <span
              style="margin-left: 70px; color: red"
              id="previousMonitoredRouteMessage"
            ></span>
          </label>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          (click)="createConfiguration()"
        >
          Salvar
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="isModalOpen = false"
        >
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>
