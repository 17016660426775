<div *ngIf="broadcastType; else selectBroadcastType">
  <app-page-title icon="fa-bullhorn" [title]="title">
    <div class="pull-right">
      <a
        *ngIf="broadcastId"
        [routerLink]="['/broadcasts/new']"
        class="btn btn-info me-2"
      >
        <i class="fa fa-envelope-o"></i>
        Novo Broadcast
      </a>
      <button
        class="btn btn-primary me-2"
        (click)="changeBroadcastType('list')"
      
        *ngIf="broadcastType !== 'list' && !broadcast?.id"
        
      >
        <i class="fa fa-list"></i>
        Para Lista
      </button>
      <button
        class="btn btn-danger me-2"
        (click)="changeBroadcastType('number')"
        *ngIf="broadcastType !== 'number' && !broadcast?.id"

      >
        <i class="fa fa-phone"></i>
        Para Números
      </button>
      <button
        class="btn btn-info"
        (click)="changeBroadcastType('client')"
        *ngIf="isAdministrator"
 
      >
        <i class="fa fa-user"></i>
        Para Clientes
      </button>
    </div>
  </app-page-title>

  <div
    class="card gradient-row primary"
    *ngIf="broadcast?.id && isImporting(broadcast)"
  >
    Os Leads deste Broadcast estão sendo importados. Assim que finalizar você
    será notificado.
  </div>

  <form [formGroup]="broadcastForm" (submit)="save()">
    <div class="card elevated mb-2">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <div class="col-12">
                <div class="card gradient-row danger">
                  ATENÇÃO: certifique-se que os numeros importados são validos, no formato DDD + 99999-9999. Numeros invalidos, serão disparados e cobrados conforme operadora.
                </div>
              </div>
              <label for="name">Automação</label>
              <input
                type="text"
                class="form-control"
                id="name"
                formControlName="name"
                #nameInput
                data-testid="input-name"
              />
              
              <small
                *ngIf="broadcastForm.get('name').errors?.required"
                class="text-danger"
              >
                Preencha o nome da automação
              </small>
              <small
                *ngIf="broadcastForm.get('name').errors?.minlength"
                class="text-danger"
              >
                O nome da automação deve ter pelo menos 5 caracteres
              </small>
            </div>
          </div>

          <div class="col-12" *ngIf="broadcastType === 'list'">
            <div class="form-group">
              <label>Lista</label>
              <select
                name="list"
                id="list"
                class="form-control"
                formControlName="lead_list_id"
                data-testid="select-list"
              >
                <option value="ALL" *ngIf="!broadcast">Todas</option>
                <option *ngFor="let list of lists" [value]="list.id">
                  {{ list.name + " (" + list.leads_count + ")" }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-12 mt-2" *ngIf="broadcastType === 'client'">
            <label>Tipo de cliente:</label>
            <div class="d-flex gap-4">
              <app-radio-button
                *ngFor="let profile of profiles"
                name="profile_{{ profile.id }}"
                [text]="profile.name"
                [value]="profile.id"
                [isCheckbox]="true"
                [checked]="profile.selected"
                (onSelect)="profile.selected = $event"
              ></app-radio-button>
            </div>
          </div>

          <div class="col-12" *ngIf="broadcastType === 'number'">
            <div class="form-group">
              <label for="numbers">Números</label>
              <textarea
                data-testid="input-numbers"
                name="numbers"
                id="numbers"
                class="form-control"
                formControlName="numbers"
                rows="8"
                placeholder="Cole aqui os números um abaixo do outro ou separados por vírgulas.

Exemplo:
14999999999
11999999999
-ou-
14999999999, 11999999999"
              ></textarea>
              <small><b>Quantidade:</b> {{ numbersCount }} número(s)</small>
            </div>
          </div>

          <div class="col-md-6 mt-3 mb-3">
            <app-switcher
              display="block"
              label="Enviar Agora?"
              id="send-now"
              [(checked)]="sendNow"
              data-testid="send-now"
            ></app-switcher>
            <div class="col-md-3 mt-3 mb-3 scheduled-date" *ngIf="!sendNow">
              <div *ngIf="!broadcastId || changeDate; else sentDateInfo">
                <mat-form-field>
                  <input matInput [ngxMatDatetimePicker]="picker" readonly placeholder="Data de agendamento" formControlName="scheduled_date" [disabled]="false">
                  <ngx-mat-datepicker-toggle matSuffix [for]="picker" data-testid="date-time-picker"></ngx-mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false"
                    [stepHour]="1" [stepMinute]="1" [stepSecond]="1" [touchUi]="false"
                    [enableMeridian]="true" [disableMinute]="false" [hideTime]="false">
                  </ngx-mat-datetime-picker>
                </mat-form-field>
              </div>

              <ng-template #sentDateInfo>
                <label for="date">Agendado para: </label>
                <button
                  class="btn btn-primary"
                  ngbTooltip="Alterar data"
                  (click)="toggleChangeDate()"
                >
                  <i class="fa fa-clock-o"></i>
                </button>
                <br />
                <span>{{
                  broadcastForm.get("scheduled_date").value
                    | date : "dd/MM/yyyy, H:mm"
                }}</span>
              </ng-template>
            </div>
          </div>

          <div
            class="col-md-3 mt-3 mb-3"
            *ngIf="broadcastId && broadcastType === 'number'"
          >
            <app-switcher
              display="block"
              label="Excluir contatos existentes?"
              id="delete-contacts"
              [(checked)]="removeContacts"
            ></app-switcher>
          </div>

          <div class="col-12">
            <div class="card gradient-row danger">
              ATENÇÃO: Lembre-se que está enviando SMS e esta tecnologia tem suas
              restrições, portanto, não utilize acento ou emoji nas mensagens!
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label for="message">Mensagem</label>
              <textarea
                name="message"
                id="message"
                class="form-control"
                formControlName="message"
                maxlength="150"
                rows="3"
                (blur)="setPosition($event)"
                data-testid="input-message"
              ></textarea>
              <small
                class="pull-right"
                *ngIf="
                  broadcastForm.get('message').value;
                  else textLengthCounter
                "
              >
                {{ broadcastForm.get("message").value.length }}/150
              </small>
              <ng-template #textLengthCounter>
                <small class="pull-right">0/150</small>
              </ng-template>
              <small
                class="text text-danger"
                *ngIf="broadcastForm.get('message').errors?.maxlength"
              >
                O texto deve ter apenas 150 caracteres
              </small>
              <small
                class="text text-danger"
                *ngIf="broadcastForm.get('message').errors?.required"
              >
                Preencha a sua mensagem
              </small>
              <div class="mt-2">
                <div class="mb-2">
                  <b
                    >Variáveis que podem ser utilizadas (clique para
                    adicionar)</b
                  >
                </div>
                <label
                  *ngFor="let tag of tagsForType"
                  ngbTooltip="{{ tag.text }}"
                >
                  <span
                    class="badge bg-primary me-2"
                    (click)="addTag(tag.tag)"
                    >{{ "{" + tag.tag + "}" }}</span
                  >
                </label>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label for="url">Meu link</label>
              <input
                type="text"
                class="form-control"
                name="url"
                id="url"
                placeholder="Exemplo: https://exemplo.com"
                formControlName="url"
              />
              <span
                class="text-danger"
                *ngIf="broadcastForm.get('url').errors?.invalidUrl"
                >URL Inválida! Exemplo: <u>https://exemplo.com</u></span
              >
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-3">
          <div class="form-group">
            <app-switcher id="user_active" label="Enviar número do lead na URL?" display="block" [(checked)]="sendLeadNumber" />
            <p class="card gradient-row danger" *ngIf="sendLeadNumber">ATENÇÂO: Ao habilitar esta opção, a importação do Broadcast ficará mais lenta.
              Ative apenas se realmente precisa desta informação na URL.</p>
          </div>
        </div>

        <div class="mt-2">
          <button
            class="btn btn-primary me-1"
            [disabled]="broadcastForm.invalid || isSaving"
            data-testid="btn-save"
          >
            Salvar
          </button>

          <a [routerLink]="['/broadcasts']" class="btn btn-secondary"
            >Cancelar</a
          >
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #selectBroadcastType>
  <div>
    <app-page-title icon="fa-bullhorn" [title]="title"></app-page-title>
    <div class="card elevated select-type">
      <div class="row">
        <div class="col mt-3 mb-3">
          <button
            class="btn btn-lg btn-primary"
            (click)="changeBroadcastType('list')"
            data-testid="btn-list"
          >
            <i class="fa fa-list"></i>
            Para Lista
          </button>
          <button
            class="btn btn-lg btn-danger ms-2"
            (click)="changeBroadcastType('number')"
            data-testid="btn-number"
          >
            <i class="fa fa-phone"></i>
            Para Números
          </button>
          <button
            class="btn btn-lg btn-info ms-2"
            (click)="changeBroadcastType('client')"
            *ngIf="isAdministrator"
            data-testid="btn-client"
          >
            <i class="fa fa-user"></i>
            Para Clientes
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
