import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FractionationRoute } from 'src/app/models/Fractionation';
import { PaginatedResult } from './../model/paginated-result';

@Injectable({
  providedIn: 'root',
})
export class FractionationService {
  constructor(private http: HttpClient) {}

  getRoutes(page: number, perPage: number): Observable<FractionationRoute[]> {
    return this.http.get<FractionationRoute[]>(
      `${environment.apiUrl}/fractionations?no-paginate=true}`
    );
  }

  listPaginated(
    page: number,
    perPage: number,
    routeName: string = ''
  ): Observable<PaginatedResult<FractionationRoute[]>> {
    return this.http.get<PaginatedResult<FractionationRoute[]>>(
      `${environment.apiUrl}/fractionations?page=${page}&per_page=${perPage}&route_name=${routeName}`
    );
  }

  addRoute(route: FractionationRoute): Observable<FractionationRoute> {
    return this.http.post<FractionationRoute>(
      `${environment.apiUrl}/fractionations`,
      route
    );
  }

  changeGroupActive(route: FractionationRoute): Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}/fractionations/changeGroupActive/${route.id}`,
      {
        active: route.active,
      }
    );
  }

  getRoute(id: string): Observable<FractionationRoute> {
    return this.http.get<FractionationRoute>(
      `${environment.apiUrl}/fractionations/${id}`
    );
  }
}
