<div class="leads card mt-3 elevated">
  <div class="card-body">
    <h3>
      <p>Leads (Qtd: {{ result?.total }})</p>

      <button class="btn btn-success btn-icon-right me-2" *ngIf="exportLeads" (click)="export()" data-testid="btn-export-leads">
        <i class="fa fa-users"></i>
        Exportar Leads
      </button>

      <button
        class="btn btn-info btn-icon-right"
        (click)="open(modalImportacao)"
        *ngIf="showImportButton && isUserValidated"
        data-testid="btn-import-leads"
      >
        <i class="fa fa-users"></i>
        Importar Leads
      </button>
    </h3>

    <form (submit)="listPaginated()">
      <div class="form-group">
        <div class="input-group">
          <input
            type="text"
            name="filter"
            class="form-control"
            placeholder="Pesquisar pelo telefone, nome ou e-mail"
            [(ngModel)]="filter"
            data-testid="input-search-leads"
          />
          <button class="btn btn-primary">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
    </form>

    <section>
      <table class="table table-hover elevated">
        <thead>
          <tr>
            <th style="width: 300px">Nome</th>
            <th class="hidden-sm" style="width: 100px">Telefone</th>
            <th class="hidden-sm">E-mail</th>
            <th class="hidden-sm" style="width: 180px">Dt. Cadastro</th>
            <th style="width: 170px"></th>
          </tr>
        </thead>
        <tbody *ngIf="result">
          <tr *ngFor="let lead of result.data">
            <td>
              {{ lead.name }}
              <span class="show-sm">{{ lead.phone }}</span>
              <span class="show-sm">{{
                lead.created_at | date : "dd/MM/yyyy, H:mm"
              }}</span>
            </td>
            <td class="hidden-sm">{{ lead.phone }}</td>
            <td class="hidden-sm">{{ lead.email }}</td>
            <td class="hidden-sm">
              {{ lead.created_at | date : "dd/MM/yyyy, H:mm" }}
            </td>
            <td style="text-align: right; width: 325px;">
              <button class="btn" ngbTooltip="QRCode do PIX"
                      *ngIf="lead.pix_code"
                      (click)="copy('QRCode do Pix', lead.pix_code )">
                <img src="/assets/pix.gif" width="25" />
              </button>

              <a
                class="btn btn-primary"
                ngbTooltip="Chamar no whatsapp"
                [href]="getWhatsapp(lead)"
                target="_blank"
              >
                <i class="fa fa-whatsapp"></i>
              </a>

              <button class="btn btn-light ms-1" *ngIf="lead.tracking_code"
                [ngbTooltip]="'Código de rastreio: ' + lead.tracking_code"
                    (click)="copy('Código de rastreio', lead.tracking_code)"
              >
                <i class="fa fa-car" aria-hidden="true"></i>
              </button>
              <button class="btn btn-light ms-1" *ngIf="lead.tracking_url"
                ngbTooltip="URL de rastreio"
                (click)="copy('URL de rastreio', lead.tracking_url)"
              >
                <i class="fa fa-external-link-square"></i>
              </button>
              <button
                class="btn ms-1 btn-light"
                ngbTooltip="URL Checkout: {{ getCheckoutUrl(lead) }}"
                (click)="copy('URL do checkout', getCheckoutUrl(lead))"
                *ngIf="getCheckoutUrl(lead)"
              >
                <i class="fa fa-file-code-o"></i>
              </button>

              <button
                class="btn ms-1 btn-light"
                ngbTooltip="URL Boleto: {{ getTicketUrl(lead) }}"
                (click)="copy('URL do boleto', getTicketUrl(lead))"
                *ngIf="getTicketUrl(lead)"
              >
                <i class="fa fa-file-o"></i>
              </button>

              <button
                class="btn ms-1 btn-light"
                ngbTooltip="URL PIX: {{ getPixUrl(lead) }}"
                (click)="copy('URL do PIX', getPixUrl(lead))"
                *ngIf="getPixUrl(lead)"
              >
                <i class="fa fa-file-o"></i>
              </button>

              <button
                class="btn ms-1 btn-light"
                ngbTooltip="URL Customizada: {{ getCustomizedUrl(lead) }}"
                (click)="copy('URL customizada', getCustomizedUrl(lead))"
                *ngIf="getCustomizedUrl(lead)"
              >
                <i class="fa fa-file-o"></i>
              </button>

              <button
                class="btn ms-1 btn-light"
                ngbTooltip="URL página de Membros: {{ getMembersUrl(lead) }}"
                (click)="copy('URL da página de membros', getMembersUrl(lead))"
                *ngIf="getMembersUrl(lead)"
              >
                <i class="fa fa-file-o"></i>
              </button>

              <button
                class="btn ms-1 btn-danger"
                ngbTooltip="Excluir lead"
                (click)="confirmDelete(lead)"
              >
                <i class="fa fa-trash"></i>
              </button>

              <button
                class="btn ms-1 btn-success"
                *ngIf="lead.message_content"
                ngbTooltip="Visualizar texto livre"
                (click)="showLeadText(lead)"
              >
                <i class="fa fa-text-width"></i>
              </button>

              <button
                class="btn ms-1 btn-warning"
                ngbTooltip="Visualizar sequências"
                [routerLink]="['/lead', lead.id, 'sequences']"
              >
                <i class="fa fa-envelope-o"></i>
              </button>
              
              <button
                class="btn ms-1 btn-info"
                ngbTooltip="Visualizar postback"
                [routerLink]="['/lead', lead.id, 'postback']"
                *ngIf="showPostbackButton && showPostbacks(lead)"
              >
                <i class="fa fa-arrow-circle-right"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <ng-template #noContacts>
          <tbody>
            <tr>
              <td colspan="3">Nenhum lead cadastrado</td>
            </tr>
          </tbody>
        </ng-template>
      </table>
      <app-pagination
        *ngIf="result"
        [currentPage]="result.current_page"
        [itemsPerPage]="result.per_page"
        [totalItems]="result.total"
        (pageChanged)="pageChanged($event)"
      >
      </app-pagination>
    </section>
  </div>
</div>

<ng-template #modalImportacao let-modal>
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Importar Contatos</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row mb-2">
        <div class="col">
          <app-radio-button
            text="Arquivo csv"
            [checked]="fromFile"
            name="fromFile"
            (onSelect)="fromFile = true"
            data-testid="input-upload-csv"
          ></app-radio-button>
        </div>
        <div class="col">
          <app-radio-button
            text="Campo de Texto"
            [checked]="!fromFile"
            name="fromField"
            (onSelect)="fromFile = false"
          ></app-radio-button>
        </div>
      </div>
      <form>
        <div class="form-group">
          <app-file
            *ngIf="fromFile"
            class="mt-2 mb-2"
            extensionAccepted=".csv"
            (fileSelected)="changeFile($event)"
          ></app-file>
        </div>

        <div class="alert alert-info">
          <p *ngIf="fromFile">Arquivo no formato CSV. Exemplo:</p>
          <p *ngIf="!fromFile">Exemplo:</p>
          <span>Não precisa incluir o cabeçalho nome;telefone</span>
          <br>
          <span class="text-danger">Parâmetros obrigatórios estão marcados com *</span>
          <br>
          <code>
            nome*;telefone*;email;texto<br />
            Marcelo;61999886677<br />
            Lucas;21997786004;email@example.com<br />
          </code>
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'animate__animated animate__shakeX': animateError,
            'has-error': importError
          }"
          *ngIf="!fromFile"
        >
          <label for="leads">Contatos</label>
          <textarea
            name="leads"
            id="leads"
            class="form-control"
            rows="5"
            placeholder="Cole aqui os contatos separando nome e número no mesmo formato do arquivo CSV"
            [(ngModel)]="text"
            data-testid="txt-area-write-leads"
          ></textarea>
        </div>

        <div class="row mt-2">
          <div class="col">
            <app-switcher
              display="block"
              [checked]="deleteLeads"
              (checkedChange)="deleteLeads = !deleteLeads"
              label="Excluir leads existentes?"
            ></app-switcher>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="import(modal)"
        data-testid="btn-save-import-leads"
      >
        Importar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #modalLeadText let-modalLeadText>
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Texto livre</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modalLeadText.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <h3>Texto do lead:</h3>
      <input type="text" class="form-control" [value]="leadText" readonly>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="modalLeadText.dismiss()"
      >
        Fechar
      </button>
    </div>
  </div>
</ng-template>
