<app-page-title icon="fa-list" title="Listas" data-testid="lists-section">
  <a [routerLink]="['/lists/new']" class="btn btn-primary btn-icon-right" data-testid="btn-new-list">
    Nova lista <i class="fa fa-plus-circle"></i>
  </a>
</app-page-title>

<div class="card mb-2" *ngIf="!user?.validated">
  <span data-testid="span-msg-unvalidated-user">ATENÇÃO: Por enquanto você pode montar seus funis de vendas apenas com listas automáticas, integradas com as
    diversas
    plataformas disponíveis no menu integrações. Após a validação do seu cadastro você poderá criar listas manuais, onde
    você poderá importar sua lista de
    leads ou realizar a integração diretamente do seu website. Para agilizar a liberação destas funcionalidades, entre
    em contato conosco através do suporte clicando
    <a [href]="supportLink" target="_blank">aqui</a>.
  </span>
</div>

<div class="row">
  <div class="col mb-3">
    <div class="input-group">
      <input type="text" name="name" class="form-control" placeholder="Digite o nome da lista para pesquisar"
        [(ngModel)]="name" (keyup)="filterKeyPress($event)" data-testid="input-search-lists" />
      <button class="btn btn-primary" (click)="pageChanged(page)" data-testid="btn-search-lists">
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
</div>

<div class="row">
  <div class="form-group mb-3">
    <label for="integration_id">Integração</label>
    <select name="integration_id" id="integration_id" class="form-control" (change)="filterByIntegration()"
      [(ngModel)]="integrationId">
      <option value="">Todas</option>
      <option *ngFor="let integration of integrations" [value]="integration.id">
        {{ integration.platform.name }} -
        {{ integration.name }}
      </option>
    </select>
  </div>
</div>

<div class="row">
  <div class="form-group mb-3">
    <app-switcher id="dinamic_list" label="Mostrar somente listas dinâmicas?" display="block"
      [checked]="onlyDynamicLists"
      (checkedChange)="toggleDinamicLists($event)">
    </app-switcher>
  </div>
</div>

<div *ngIf="result?.data.length > 0; else notFound">

  <app-accordion-card *ngFor="let list of result.data" [data]="list"
    [title]="list.name + (list.importing ? ' [importando...]' : '') + ' (' + (list.leads_count || '0') + ')'"
    [titleIcons]="titleIcons" background="primary">
    <div class="row">
      <div class="col-md-3 col-sm-6">
        <label>Tipo</label>
        <p>{{ list.manual ? 'Manual' : 'Automática' }}</p>
      </div>
      <div class="col-md-3 col-sm-6">
        <label>Integração</label>
        <p>
          <span *ngIf="!list.manual; else manual" [ngClass]="{'text text-danger': !list?.integration?.active}">
            {{ list?.integration?.platform.name }}

            <span *ngIf="!list?.integration?.active">
              - Desativada
              <i class="fa fa-info-circle" ngbTooltip="Integração desativada não receberá os postbacks!"></i>
            </span>
          </span>
          <ng-template #manual>
            -
          </ng-template>
        </p>
      </div>
      <div class="col-md-3 col-sm-6">
        <label>Leads</label>
        <p>{{ list.leads_count || '0' }}</p>
      </div>
      <div class="col-md-3 col-sm-6">
        <label>Data último lead</label>
        <p>{{ (list.last_lead_date | date:'dd/MM/yyyy, H:mm' ) || '-' }}</p>
      </div>
    </div>
  </app-accordion-card>
</div>


<ng-template #notFound>
  <div class="card mt-2" data-testid="not-found-message">Nenhuma lista {{ name ? 'encontrada' : 'cadastrada' }}!</div>
</ng-template>

<app-pagination *ngIf="result" [currentPage]="result.current_page" [itemsPerPage]="result.per_page"
  [totalItems]="result.total" (pageChanged)="pageChanged($event)"></app-pagination>

<ng-template #modalExclusao let-modal>
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Impossível excluir no momento</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      Para excluir esta lista, você deve desvincular a lista da(s) campanha(s):
      <br>
      <ul>
        <li *ngFor="let campaign of deleteListCampaigns"><a (click)="modal.dismiss()" ngbTooltip="Clique para acessar a campanha" [href]="'/#/campaigns/edit/' + campaign.id">{{ campaign.name }}</a></li>
      </ul>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="modal.dismiss()">OK</button>
    </div>
  </div>
</ng-template>