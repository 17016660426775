<app-page-title title="Dashboard" icon="fa-tachometer" data-testid="dashboard-section"></app-page-title>

<app-plan-message></app-plan-message>

<div class="dashboard">
  <div class="row d-flex" *ngIf="creditInfo">
    <div class="col-lg-4 col-md-12 mb-2">
      <div class="card credits" data-testid="contracted-credits">
        <h2>SMS Contratados</h2>
        <p><i class="fa fa-money"></i> Total de créditos</p>
        <h3 class="fa-2x">
          <b>{{ creditInfo.total_contracted | numberFormat }}</b>
          <a class="btn-add" [routerLink]="['/recharges/new']">
            <i class="fa fa-plus-circle"></i>
          </a>
        </h3>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 mb-2">
      <div class="card sent" data-testid="sent-credits">
        <h2>SMS Enviados</h2>
        <p><i class="fa fa-envelope-o"></i> Créditos utilizados</p>
        <h3 class="fa-2x">
          <b>{{ creditInfo.total_sent | numberFormat }}</b>
        </h3>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 mb-2">
      <div class="card available" data-testid="available-credits">
        <h2>SMS Disponíveis</h2>
        <p><i class="fa fa-bank"></i> Saldo disponível</p>
        <h3>
          <b class="fa-2x">{{ (creditInfo.credits - creditInfo.total_reserved) | numberFormat }}</b>
        </h3>
      </div>
    </div>
  </div>

  <div class="row d-flex" *ngIf="creditInfo">
    <div class="col-lg-4 col-md-12 mb-2">
      <div class="card credits">
        <h2>Whatsapp Contratados</h2>
        <p><i class="fa fa-money"></i> Total de créditos</p>
        <h3 class="fa-2x">
          <b>{{ creditInfo.whatsapp_total_contracted | numberFormat }}</b>
          <a class="btn-add" [routerLink]="['/recharges/new']">
            <i class="fa fa-plus-circle"></i>
          </a>
        </h3>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 mb-2">
      <div class="card sent">
        <h2>Whatsapp Enviados</h2>
        <p><i class="fa fa-envelope-o"></i> Créditos utilizados</p>
        <h3 class="fa-2x">
          <b>{{ creditInfo.whatsapp_total_sent | numberFormat }}</b>
        </h3>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 mb-2">
      <div class="card available">
        <h2>Whatsapp Disponíveis</h2>
        <p><i class="fa fa-bank"></i> Saldo disponível</p>
        <h3>
          <b class="fa-2x">{{ (creditInfo.whatsapp_credits - creditInfo.whatsapp_total_reserved) | numberFormat }}</b>
        </h3>
      </div>
    </div>
  </div>


  <div class="row">
    <mat-form-field *ngIf="messagesChartDateField" style="width:200px">
      <mat-label>Data inicial</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="startedDay" name="startedDay" (dateChange)="onDateSelected($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <small *ngIf="startedDay > now" class="text text-danger"
      >A data inicial não pode ser maior que a data de hoje.
    </small>
    
    <apx-chart
      *ngIf="messagesChartOptions"
      style="width: 100%"
      [series]="messagesChartOptions.series"
      [chart]="messagesChartOptions.chart"
      [tooltip]="messagesChartOptions.tooltip"
      [colors]="messagesChartOptions.colors"
      [grid]="messagesChartOptions.grid"
      [xaxis]="messagesChartOptions.xaxis"
      [dataLabels]="messagesChartOptions.dataLabels"
      [title]="messagesChartOptions.title"
    >
    </apx-chart>
  </div>

  <div class="row">
    <div class="col">
      <app-page-title
        icon="fa-paper-plane"
        title="Mensagens Enviadas"
        data-testid="test-page-title"
      ></app-page-title>

      <div *ngIf="controls?.length > 0; else notFound">
        <app-accordion-panel
          *ngFor="let info of controls"
          [title]="info.message"
          data-testid="message-panel"
        >
          <div class="row message-line">
            <div class="col-md-3 col-sm-12">
              <i class="fa fa-user me-2"></i>{{ info.name }}
            </div>
            <div class="col-md-3 col-sm-12">
              <i class="fa fa-phone me-2"></i>{{ info.phone }}
            </div>
            <div class="col-md-3 col-sm-12">
              <i class="fa fa-calendar me-2"></i
              >{{ info.sent_date | date : "dd/MM/yyyy HH:mm" }}
            </div>
            <div class="col-md-3 col-sm-12" *ngIf="info.provider_account_name">
              <i class="fa fa-calendar me-2"></i
              >{{ info.provider_account_name }}
            </div>
          </div>
        </app-accordion-panel>

        <div class="text-center">
          <a
            [routerLink]="['/messages-report']"
            class="btn btn-primary btn-icon-right"
          >
            Mais Mensagens
            <i class="fa fa-plus-circle"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #notFound>
  <div class="card" data-testid="no-messages-card">Nenhuma mensagem enviada!</div>
</ng-template>
