<main>
  <div class="card">
    <div class="card-body">
      <div class="logo">
        <img src="/assets/logo-smsfunnel-login.png" alt="SMS Funnel" title="SMS Funnel" />
      </div>
      <form [formGroup]="loginForm" (submit)="login()">
        <div class="form-group mb-4">
          <label for="email" data-testid="input-email-login" >Email</label>
          <input type="email" class="form-control" id="email" name="email" formControlName="email" #inputEmail />
        </div>
        <div class="form-group">
          <label for="password">Senha</label>
          <input type="password" data-testid="input-password-login" class="form-control" id="password" name="password" formControlName="password">
        </div>
        <div class="bg-danger error-password" *ngIf="passwordAttemptsError">
          <p>
            Sua conta foi bloqueda por segurança, para liberar sua conta acione o suporte 
            <a [href]="supportLink" target="_blank">aqui</a>.
          </p>
        </div>
        <div class="form-group d-grid mb-3">
          <button type="submit" class="btn btn-primary" data-testid="btn-login">ENTRAR</button>
        </div>
        <div class="form-group">
          <a [routerLink]="['/password-reset']" data-testid="reset-password-link">
            <i class="fa fa-chevron-right"></i>
            Esqueci minha senha
          </a>
        </div>
      </form>
    </div>
  </div>
</main>
