import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/User';
import { environment } from 'src/environments/environment';

import { ListsService } from '../../shared/services/lists.service';
import { Integration } from './../../models/Integration';
import { LeadList } from './../../models/LeadList';
import { ListFilter } from './../../models/ListFilter';
import { TitleIcon } from './../../shared/accordion-card/accordion-card.component';
import { ConfirmationService } from './../../shared/components/confirmation-modal/confirmation.service';
import { ToastService } from './../../shared/components/toast/toast.service';
import { PaginatedResult } from './../../shared/model/paginated-result';
import { AuthService } from './../../shared/services/auth.service';
import { IntegrationsService } from './../../shared/services/integrations.service';
import { Campaign } from 'src/app/models/Campaign';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService } from './../../shared/components/loading/loading.service';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss']
})
export class ListsComponent implements OnInit, OnDestroy {

  get supportLink() {
    return environment.supportLink;
  }

  integrationId = "";
  name = '';
  page = 1;
  user: User;
  pageSize = 15;

  @ViewChild('modalExclusao') modal: any;

  onlyDynamicLists = false;

  titleIcons: TitleIcon[] = [
    { icon: 'fa-pencil', color: 'primary', tooltip: 'Editar', action: (data) => { this.router.navigate(['/lists/edit', data.id]); }, dataTestId: "edit-btn" },
    { icon: 'fa-times', color: 'danger', tooltip: 'Excluir', action: (data) => { this.confirmDelete(data); } }
  ];

  result: PaginatedResult<LeadList[]>;
  subscriptions: Subscription[] = [];
  integrations: Integration[] = [];
  deleteListCampaigns: Campaign[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private autService: AuthService,
    private toastService: ToastService,
    private listsService: ListsService,
    private integrationService: IntegrationsService,
    private confirmationService: ConfirmationService,
    private modalService: NgbModal,
    private loadingService: LoadingService,
  ) { }

  ngOnInit(): void {
    this.user = this.autService.getUser();

    this.subscriptions.push(
      this.listsService.importFinished.subscribe(() => this.listPaginated())
    );

    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        this.page = +params.page || 1;
        this.name = params.name || '';
        this.integrationId = params.integration_id || '';
        if (params.dynamic_lists !== undefined) {
          this.onlyDynamicLists = JSON.parse(params.dynamic_lists);
        }
        this.listPaginated();
      }),

      this.autService.userChanged.subscribe(() => this.listPaginated()),

      this.integrationService.listAll().subscribe(integrations => {
        this.integrations = integrations;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  listPaginated(): void {
    this.loadingService.start();
    const filter: ListFilter = {
      name: this.name,
      onlyDynamicLists: this.onlyDynamicLists,
      integrationId: this.integrationId
    }

    this.subscriptions.push(
      this.listsService.listPaginated(this.page, this.pageSize, filter).subscribe(result => {
        this.result = result;
        this.listsService.addAllToStorage(this.result.data);
        if (result.last_page < this.page) {
          this.pageChanged(result.last_page);
        }
        this.loadingService.stop();
      })
    ) 
  }

  filterKeyPress(event: KeyboardEvent): void {
    const key = event?.key?.toLocaleUpperCase();
    if (key === 'ENTER' || this.name === '') {
      this.pageChanged(this.page);
    }
  }

  filterByIntegration(): void {
    this.pageChanged(this.page);
  }

  toggleDinamicLists(checked: boolean): void {
    this.onlyDynamicLists = checked;
    this.pageChanged(this.page);
  }

  pageChanged(page: number): void {
    let queryParams = {
      integration_id: null,
      name: null,
      page,
      dynamic_lists: this.onlyDynamicLists,
      per_page: this.pageSize
    };

    if (this.name) {
      queryParams.name = this.name;
    }

    if (this.integrationId) {
      queryParams.integration_id = this.integrationId;
    }

    this.router.navigate(['/lists'], { queryParams });
  }

  confirmDelete(list: LeadList): void {
    if (list.campaigns.length > 0) {
      this.deleteListCampaigns = list.campaigns;
      this.modalService.open(this.modal);
      return;
    }

    this.confirmationService.show(`Deseja realmente excluir a lista ${list.name}?`, () => this.delete(list.id));
  }

  delete(id: string) {
    
    this.subscriptions.push(
      this.listsService.delete(id).subscribe(
        () => {
          this.toastService.success('Lista excluída com sucesso!');
          this.listPaginated();
        },
        () => this.toastService.error('Erro ao excluir lista! Tente novamente mais tarde'))
    );
  }

}
