<app-page-title image="assets/icon-routes.svg" title="Detalhamento de rota: ({{ routeData.name }})">
  <button (click)="redirectBack()" class="btn btn-secondary btn-back">
    Voltar
  </button>
</app-page-title>

<div class="new-carrier-container">
  <button (click)="addCarrierRow()" class="btn btn-new-carrier">
    ADICIONAR NOVA OPERADORA
    <i class="fa fa-plus-circle"></i>
  </button>
</div>

<div *ngFor="let carrier of carriers.controls; let i = index" class="card elevated">
  <div style="padding: 50px">
    <form [formGroup]="form" style="margin-bottom: 40px;">

      <mat-autocomplete #autoCompleteCarrier="matAutocomplete" [displayWith]="displayCarrierFn">
        <mat-option *ngFor="let option of filteredCarrierOptions | async" [value]="option">
          {{option.title}}
        </mat-option>
      </mat-autocomplete>

      <mat-autocomplete #autoCompleteProvider="matAutocomplete" [displayWith]="displayProviderFn">
        <mat-option *ngFor="let option of filteredProviderOptions | async" [value]="option">
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>

      <div formArrayName="carriers">
        <div [formGroupName]="i">
          <div class="carrier-title">
            <span>Operadora</span>
          </div>
          <div class="carrier-container-fields">
            <mat-form-field appearance="fill" class="mat-form-field-custom">
              <mat-label>Selecione a operadora</mat-label>
              <input type="text" matInput formControlName="carrierControl" [matAutocomplete]="autoCompleteCarrier"
                (focus)="loadCarrierAutoComplete(i)" />
            </mat-form-field>
            <div class="percentage-input-container">
              <span>Porcentagem restante</span>
              <input type="text" class="percentage-input" readonly [value]="getRowRemainingPercentage(i) + '%'">
            </div>
            <div style="width: 50px; height: 1px;"></div>
          </div>

          <div class="provider-container-fields">
            <span>Saídas</span>
          </div>

          <div formArrayName="providers">
            <div class="provider-container" *ngFor="let provider of getProviders(i).controls; let j = index"
              [formGroupName]="j">
              <mat-form-field class="mat-form-field-custom">
                <mat-label>Conta de provedor</mat-label>
                <input type="text" matInput [matAutocomplete]="autoCompleteProvider" formControlName="providerControl"
                  (focus)="loadProviderAutoComplete(carrier, j)">

              </mat-form-field>

              <div class="percentage-input-container">
                <span>Porcentagem distribuida</span>
                <input type="text" class="percentage-input" (input)="formatPercent($event)"
                  formControlName="percentageControl">
              </div>

              <button type="button" (click)="removeProviderRow(carrier, j)" ngbTooltip="Remover saída"
                class="btn btn-secondary btn-remove-provider">
                <span class="btn-secondary">x</span>
              </button>
            </div>
          </div>
          <div class="add-provider-btn-container">
            <button class="btn btn-new-carrier" style="width: 40%;" type="button" (click)="addProviderRow(carrier)">
              <i class="fa fa-plus-circle"></i>
              Fracionar
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="decision-buttons">
      <button type="button" class="btn btn-primary bubble-btn" (click)="saveValues()">
        Salvar
      </button>
      <button type="button" class="btn btn-secondary bubble-btn" (click)="removeCarrierRow(i)">
        Cancelar
      </button>
    </div>
  </div>
</div>